import { gql, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

import ErrorMessage from '~/components/ErrorMessage';
import Bugsnag from '~/utils/bugsnag';

const currentUser = gql`
  query CurrentUser {
    refreshUser {
      id
      name
      roles
    }
  }
`;

const Center = ({ children }) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    minHeight="100vh"
  >
    {children}
  </Box>
);

Center.propTypes = {
  children: PropTypes.node.isRequired,
};

function Auth({ children }) {
  const { data, loading, error } = useQuery(currentUser);
  const user = data?.refreshUser;

  useEffect(() => {
    if (user != null && user.roles.includes('admin')) {
      Bugsnag.setUser({ user: user.email });
    }
  }, [user]);

  useEffect(() => {
    if (error?.networkError?.statusCode === 401) {
      window.location.href = `${
        process.env.REACT_APP_AUTH_URL
      }?ReturnUrl=${encodeURI(window.location.href)}`;
    }
  }, [error?.networkError?.statusCode]);

  if (loading) {
    return (
      <Center>
        <CircularProgress />
      </Center>
    );
  }

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (!user.roles.includes('admin')) {
    return (
      <Center>
        <Card css={{ minWidth: '300px' }}>
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
              Du saknar admin behörighet
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              component="a"
              href={`${
                process.env.REACT_APP_AUTH_URL
              }/logout?ReturnUrl=${encodeURI(window.location.href)}`}
              color="primary"
            >
              Logga ut
            </Button>
          </CardActions>
        </Card>
      </Center>
    );
  }

  return children;
}

Auth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Auth;
